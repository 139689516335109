import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import apiNext from 'api-next';
import { NotificationData } from 'shared-components/ToasterNotification/SystemNotification';
import { InstitutionApi } from 'types/backend/institutions.types';
const apiUrl = process.env.REACT_APP_API_NEXT_URL;
const systemNotificationsRootUrl = process.env.REACT_APP_SYSTEM_NOTIFCATIONS_ROOT_URL || 'https://codonlearning.github.io/system-notifications';

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = apiNext.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (apiNext.masqueradeUserId) {
      headers.set('x-masquerade-as', apiNext.masqueradeUserId);
    }
    return headers;
  },
});

export const baseApi = createApi({
  baseQuery: retry(baseQuery, { maxRetries: 5 }),
  endpoints: (build) => ({
    getInstitutions: build.query<Array<InstitutionApi>, void>({
      query: () => ({ url: '/institutions?$sort[name]=1' }),
      transformResponse: (response: { data: Array<InstitutionApi> }, meta, arg) => response.data,
    }),
    getNotifications: build.query<Array<NotificationData>, number>({
      queryFn: async (timestamp) => {
        try {
          // using fetch here because the default query runs into CORS issues
          const result = await fetch(`${systemNotificationsRootUrl}/notifications.json?${timestamp}`);
          const data = await result.json();
          return { data };
        } catch (error) {
          return {
            error: {
              status: 500,
              statusText: 'Unable to fetch notifications data',
              data: 'no notifications fetched',
            },
          };
        }
      },
    }),
  }),
});

export const { useGetInstitutionsQuery, useGetNotificationsQuery } = baseApi;
