import React, { useEffect, useRef } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FaMask, FaUserFriends } from 'react-icons/fa';
import { DateTime } from 'luxon';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { formatStringsWithAnd } from 'utils/commonFormattingFunctions';
import sharedStrings from 'sharedStrings';
import { availableZoneOptions } from 'codonConstants';
import { useAppSelector } from 'store';
import LoadingButton from 'shared-components/LoadingButton/LoadingButton';
import BetterButton from 'shared-components/BetterButton/BetterButton';
import CourseDetailsItem from './CourseDetailsItem';
import ProductDetails from 'shared-components/ProductDetails/ProductDetails';
import BetterTooltip from 'shared-components/Tooltip/BetterTooltip';
import InstitutionsDropdown from 'instructor/components/Dropdown/InstitutionsDropdown';
import ToggleSwitch from 'shared-components/ToggleSwitch/ToggleSwitch';
import ClassDayPicker from './ClassDayPicker';
import { CourseApi } from 'types/backend/courses.types';
import { DropdownOption } from 'instructor/components/Dropdown/Dropdown.types';
import { YesNo } from 'types/backend/shared.types';
import { UserApiIdNameEmail } from 'types/backend/users.types';
import { ChildrenProp, PositionEnum } from 'types/common.types';
import './CourseDetails.scss';

const defaultProductId = Number(process.env.REACT_APP_DEFAULT_PRODUCT_ID) || 1;

export interface CourseValues extends Pick<CourseApi,
  'additionalSubjectIds' |
  'courseNumber' |
  'creatorUserId' |
  'enrollable' |
  'institutionId' |
  'isGradeSyncEnabled' |
  'name' |
  'productId'
> {
  courseDays: Array<number> // Fix for TS2589: Type instantiation is excessively deep and possibly infinite.
  startDate: Date
  endDate: Date
  accessDate: Date
  graceEndDate: Date
  selectedSubject: DropdownOption<number>
  selectedTimeZone: DropdownOption<string>
  selectedAdditionalSubjects: MultiValue<DropdownOption<number>>
}

function CourseDetails({ course, onCancel, onCreate, onSave }: {
  course?: CourseApi
  onCreate?: (courseDetails: Omit<CourseApi, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>
  onSave?: (courseDetails: CourseApi) => Promise<void>
  onCancel: () => void
}) {
  const user = useAppSelector((store) => store.user);
  const adminUser = useAppSelector((store) => store.adminUser);
  const subjects = useAppSelector((store) => store.passive.subjects);
  const products = useAppSelector((store) => store.passive.products);

  const instructors = useAppSelector(store => store.active.instructors);
  let coinstructors: Array<UserApiIdNameEmail> = [];
  if (course) {
    coinstructors = instructors.filter((i) => i.id !== user.id);
  }

  // configure course dates
  const now = DateTime.local();
  const defaultCourseLength = { weeks: 16 };
  const defaultGracePeriod = { days: 21 };
  const initStartDate = !!course ? DateTime.fromISO(course.startDate).toJSDate() : now.toJSDate();
  const initAccessDate = !!course ? DateTime.fromISO(course.accessDate).toJSDate() : initStartDate;
  const initGraceEndDate = !!course ? DateTime.fromISO(course.graceEndDate).toJSDate() : DateTime.fromJSDate(initStartDate).plus(defaultGracePeriod).toJSDate();
  const initEndDate = !!course ? DateTime.fromISO(course.endDate).toJSDate() : now.plus(defaultCourseLength).toJSDate();

  // Subject
  // hide certain subjects from dropdown for new courses
  const subjectsForDropdown = !course ? subjects.filter(({ show }) => show === YesNo.Yes) : subjects;
  const subjectDropdownOptions = subjectsForDropdown.map(t => ({ value: t.id, label: t.name }));
  const initSelectedSubject = subjectDropdownOptions.find(({ value }) => value === course?.subjectId) || { value: -1, label: '' } as DropdownOption<number>;

  // Time Zone
  const tzValue = course?.timeZone || DateTime.now().zoneName;
  const initSelectedTimeZone = availableZoneOptions.find(({ value }) => value === tzValue) || {} as DropdownOption<string>;

  const welcomeSubjectRef = useRef(subjects.find(({ stringId }) => stringId === 'CODON'));

  // additional subjects
  const initAdditionalSubjectIds: Array<number> = [];
  if (course?.additionalSubjectIds) {
    initAdditionalSubjectIds.push(...course.additionalSubjectIds);
  } else {
    if (welcomeSubjectRef.current) {
      initAdditionalSubjectIds.push(welcomeSubjectRef.current.id);
    }
  }
  const additionalSubjectsForDropdown = subjects.reduce((acc: Array<DropdownOption<number>>, cur) => {
    if (cur.id !== course?.subjectId) {
      acc.push({
        value: cur.id,
        label: `${cur.name}${cur.show === YesNo.No ? ' (hidden)' : ''}`,
      });
    }
    return acc;
  }, []);
  const initSelectedAdditionalSubjects = additionalSubjectsForDropdown.filter(({ value }) => initAdditionalSubjectIds.includes(value));

  // products
  const productsForDropdown = products.map(p => ({
    value: p.id,
    label: p.title,
  }));

  const initFormState: CourseValues = {
    additionalSubjectIds: initAdditionalSubjectIds,
    courseDays: course?.courseDays || [],
    courseNumber: course?.courseNumber || '',
    creatorUserId: course?.creatorUserId || user.id,
    enrollable: course?.enrollable || true,
    institutionId: course?.institutionId || null,
    isGradeSyncEnabled: !!course?.isGradeSyncEnabled,
    name: course?.name || '',
    productId: course?.productId || defaultProductId,
    // react-select fields - converted from DropdownOption on save
    selectedSubject: initSelectedSubject,
    selectedTimeZone: initSelectedTimeZone,
    selectedAdditionalSubjects: initSelectedAdditionalSubjects,
    // JS Date values - converted to ISO string on save
    startDate: initStartDate,
    endDate: initEndDate,
    accessDate: initAccessDate,
    graceEndDate: initGraceEndDate,
  };

  const {
    control,
    formState: { errors, isValid, isDirty, isSubmitting },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({ defaultValues: initFormState, mode: 'onTouched' });

  const saveDisabled = !isValid || !isDirty;

  const currentSubject = watch('selectedSubject');
  const additionalSubjects = watch('selectedAdditionalSubjects');
  const previousAdditionalSubjectsRef = useRef(additionalSubjects);
  const courseStartDate = watch('startDate');
  const courseEndDate = watch('endDate');
  const productId = watch('productId');
  const product = products.find(p => p.id === productId);
  // Start date cannot be after end date
  const invalidDurationDates = courseStartDate >= courseEndDate;

  // this useEffect adjusts the Welcome to Codon (WtC) additional subject depending on the course primary subject.
  // it always removes the WtC if the primary subject is ILS and adds the default if it is not ILS whenever the subject is changed.
  // NOTE: This should only take effect when creating new courses, it should not remove WtC from duped courses
  useEffect(() => {
    if (!course) {
      const currentSelectedSubject = subjects.find(({ id }) => id === currentSubject?.value);
      if (currentSelectedSubject?.stringId === 'ILS') {
        // switching to ILS. Remove the WtC if present
        const newAdditionalSubjects = additionalSubjects.filter(({ value }) => value !== welcomeSubjectRef.current?.id);
        if (JSON.stringify(newAdditionalSubjects) !== JSON.stringify(previousAdditionalSubjectsRef.current)) { // avoid infinite loop
          setValue('selectedAdditionalSubjects', newAdditionalSubjects);
          previousAdditionalSubjectsRef.current = newAdditionalSubjects;
        }
      } else {
        // switching to non-ILS. Add the WtC if not present
        const newAdditionalSubjects = additionalSubjects.find(({ value }) => value === welcomeSubjectRef.current?.id) ? additionalSubjects : [...additionalSubjects, ...additionalSubjectsForDropdown.filter(({ value }) => value === welcomeSubjectRef.current?.id)];
        if (JSON.stringify(newAdditionalSubjects) !== JSON.stringify(previousAdditionalSubjectsRef.current)) { // avoid infinite loop
          setValue('selectedAdditionalSubjects', newAdditionalSubjects);
          previousAdditionalSubjectsRef.current = newAdditionalSubjects;
        }
      }
    }
  }, [course, currentSubject, setValue, subjects, additionalSubjects, additionalSubjectsForDropdown]);

  const onSubmit: SubmitHandler<CourseValues> = async (formData) => {
    const {
      selectedSubject,
      selectedTimeZone,
      selectedAdditionalSubjects,
      startDate,
      endDate,
      accessDate,
      graceEndDate,
      ...courseValues
    } = formData;
    const courseValuesToCourseApi = {
      ...courseValues,
      subjectId: selectedSubject.value,
      timeZone: selectedTimeZone.value,
      additionalSubjectIds: selectedAdditionalSubjects.map(({ value }) => value),
      startDate: DateTime.fromJSDate(startDate).toISODate(),
      endDate: DateTime.fromJSDate(endDate).toISODate(),
      accessDate: DateTime.fromJSDate(accessDate).toISODate(),
      graceEndDate: DateTime.fromJSDate(graceEndDate).toISODate(),
    };
    if (course) {
      !!onSave && await onSave({
        ...course,
        ...courseValuesToCourseApi,
      });
    } else {
      !!onCreate && await onCreate({
        ...courseValuesToCourseApi,
        ltiRegistrationId: null,
        accessInstructions: null,
      });
    }
  };

  return (
    <div className="course-details">
      <div className="form">
        <h2 className="course-details__title">Course Details</h2>
        <form className="course-details__form" onSubmit={handleSubmit(onSubmit)}>
          <CourseDetailsItem
            id="course-subject"
            label="Course Subject"
          >
            <div className="input-wrap">
              <Controller
                control={control}
                name="selectedSubject"
                rules={{
                  required: 'You must select a subject',
                  validate: (selected) => !!selected.value && selected.value !== -1,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={subjectDropdownOptions}
                    name="subjectId"
                    inputId="course-subject"
                    classNamePrefix="course-details__select-Subject"
                    placeholder="Select Subject"
                    isDisabled={!!course}
                  />
                )}
              />
            </div>
          </CourseDetailsItem>
          <CourseDetailsItem
            id="course-name"
            label="Course Name"
            errorMsg={errors?.name?.message}
          >
            <div className="input-wrap">
              <input
                type="text"
                id="course-name"
                {...register('name', {
                  required: 'Course name is required',
                  minLength: { value: 2, message: 'Course name too short' },
                  maxLength: { value: 70, message: 'Course name too long' },
                })}
              />
            </div>
          </CourseDetailsItem>
          <CourseDetailsItem
            id="course-number"
            label="Course Number"
            errorMsg={errors?.courseNumber?.message}
          >
            <div className="input-wrap">
              <input
                type="text"
                id="course-number"
                {...register('courseNumber', {
                  required: 'Course number is required',
                  minLength: { value: 1, message: 'Course number too short' },
                  maxLength: { value: 50, message: 'Course number too long' },
                })}
              />
            </div>
          </CourseDetailsItem>
          {!!coinstructors.length && (
            <div className="course-details__coinstructors">
              <BetterTooltip content={`To add or remove co-instructors, reach out to your ${sharedStrings.INSTRUCTOR_SUPPORT_TITLE}.`} position={PositionEnum.BottomRight}>
                <FaUserFriends/>
              </BetterTooltip>
              This course is co-taught with {formatStringsWithAnd(coinstructors.map(co => `${co.firstName} ${co.lastName}`))}
            </div>
          )}
          {!!course && (
            <div className="course-details__warning-text">
              Course subject, duration, and class days can no longer be edited.
            </div>
          )}
          <CourseDetailsItem
            id="course-duration"
            label="Course Duration"
            errorMsg={invalidDurationDates && 'Please adjust dates. End date must be later than start date.'}
            data-has-error={invalidDurationDates}
          >
            <div className="course-details__duration__date-pickers">
              <Controller
                control={control}
                name="startDate"
                render={({ field }) => (
                  <DatePicker
                    disabled={!!course}
                    name="startDate"
                    aria-label="Course Start Date"
                    onChange={field.onChange}
                    selected={field.value}
                  />
                )}
              />
              <span>to</span>
              <Controller
                control={control}
                name="endDate"
                render={({ field }) => (
                  <DatePicker
                    disabled={!!course}
                    name="endDate"
                    aria-label="Course End Date"
                    onChange={field.onChange}
                    selected={field.value}
                  />
                )}
              />
            </div>
          </CourseDetailsItem>
          <div className="course-details__class-days">
            <div className="course-details__class-days-label">
              When does class meet? <sub>(select all days that apply)</sub>
            </div>
            <Controller
              control={control}
              name="courseDays"
              rules={{ required: 'You must select class days' }}
              render={({ field }) => (
                <ClassDayPicker
                  updateDays={field.onChange}
                  disabled={!!course}
                  days={field.value}
                />
              )}
            />
          </div>
          <CourseDetailsItem id="course-access-date" label="Course Access Date">
            <div className="input-wrap">
              <Controller
                control={control}
                name="accessDate"
                render={({ field }) => (
                  <DatePicker
                    id="course-access-date"
                    maxDate={courseEndDate}
                    name="accessDate"
                    aria-label="Course Access Date"
                    onChange={field.onChange}
                    selected={field.value}
                  />
                )}
              />
            </div>
            <small>
              This is the first day students can view and start working on the course.
            </small>
          </CourseDetailsItem>
          {!!course && (
            <>
              <div className="grade-sync-toggle">
                <Controller
                  control={control}
                  name="isGradeSyncEnabled"
                  render={({ field }) => (
                    <ToggleSwitch
                      id="isGradeSyncEnabled"
                      label="Enable Grade Sync to LMS"
                      checked={field.value}
                      disabled={!course.contextId}
                      onChange={field.onChange}
                    />
                  )}
                />
                {!course.contextId && (
                  <BetterTooltip
                    indicate
                    content="Grade Sync can't be enabled until the course has been linked to an LMS course"
                  />
                )}
              </div>
            </>
          )}
          <CourseDetailsAdminControl hide={!adminUser}>
            <label htmlFor="time-zone-select">Assessment Preset Time Zone</label>
            <Controller
              control={control}
              name="selectedTimeZone"
              render={({ field }) => (
                <Select
                  {...field}
                  className="form-select"
                  id="time-zone-select"
                  isDisabled={false}
                  options={availableZoneOptions}
                />
              )}
            />
            <small>
              As a Codon administrator you may {course ? 'update the' : 'select a'} time zone for this course.
              This will affect the time zone that is applied to Assessment Preset times ONLY.
              Please select the time zone that the instructor will be teaching from.
            </small>
          </CourseDetailsAdminControl>
          <CourseDetailsAdminControl hide={!adminUser}>
            <label htmlFor="grace-end-date">
              Last Grace Period Day
            </label>
            <Controller
              control={control}
              name="graceEndDate"
              render={({ field }) => (
                <DatePicker
                  maxDate={courseEndDate}
                  name="grace-end-date"
                  onChange={field.onChange}
                  selected={field.value || null}
                />
              )}
            />
            <small>
              This is the LAST day that students can access the course without paying.
            </small>
          </CourseDetailsAdminControl>
          <CourseDetailsAdminControl hide={!adminUser}>
            <label htmlFor="additional-subjects">
              Additional Subjects
            </label>
            <Controller
              control={control}
              name="selectedAdditionalSubjects"
              render={({ field }) => (
                <Select
                  {...field}
                  className="form-select"
                  options={additionalSubjectsForDropdown}
                  name="additional-subjects"
                  classNamePrefix="course-details__select-Additional-Subjects"
                  placeholder="Select Additional Subjects"
                  isMulti
                />
              )}
            />
            <small>
              These are additional subjects that will be available to the instructor in the course.
            </small>
          </CourseDetailsAdminControl>
          <CourseDetailsAdminControl hide={!adminUser}>
            <label htmlFor="course-product">
              Course Product
            </label>
            <Controller
              control={control}
              name="productId"
              render={({ field }) => (
                <Select
                  {...field}
                  value={productsForDropdown.find(({ value }) => value === field.value)}
                  onChange={(newValue: SingleValue<DropdownOption<number>>) => newValue && field.onChange(newValue.value)}
                  className="form-select"
                  options={productsForDropdown}
                  name="course-product"
                  placeholder="Select Product"
                />
              )}
            />
            {product && <ProductDetails product={product}/>}
          </CourseDetailsAdminControl>
          <CourseDetailsAdminControl hide={!adminUser}>
            <label htmlFor="institution-select">
              Course Institution
            </label>
            <Controller
              control={control}
              name="institutionId"
              render={({ field }) => (
                <InstitutionsDropdown
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
          </CourseDetailsAdminControl>
          <div className="course-details__action-buttons">
            <LoadingButton
              className="course-details__submit"
              disabled={saveDisabled}
              loadingText="Saving..."
              loading={isSubmitting}
              text={course ? 'Save Course Details' : 'Create Course'}
            />
            <BetterButton
              onClick={onCancel}
              secondary
              text="Cancel"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

CourseDetails.propTypes = {
  course: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default CourseDetails;

function CourseDetailsAdminControl({
  children,
  hide = false,
}: {
  children: ChildrenProp
  hide?: boolean
}) {
  if (hide) {
    return null;
  }
  return (
    <div className="admin-box">
      <span className="admin-box__mask-icon"><FaMask /></span>
      <div className="admin-box__contents">
        {children}
      </div>
    </div>
  );
}
