import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { createCourse, updateCourse } from './passiveCourseThunks';
import { loadInstructorPassiveData, loadCommonPassiveData } from './passiveThunks';
import defaultState from 'store/defaultState';
import { CourseApi } from 'types/backend/courses.types';
import { ProductApi } from 'types/backend/products.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { TopicApi } from 'types/backend/topics.types';
import { UnitApi } from 'types/backend/units.types';
import { UserApi } from 'types/backend/users.types';

export interface Passive {
  loading: boolean
  // common passive data
  instructorCourses: Array<CourseApi>
  studentCourses: Array<CourseApi>
  coinstructorsFromEnrolledCourses: Array<UserApi>
  courseInstructors: Array<UserApi>
  products: Array<ProductApi>
  // instructor passive data
  subjects: Array<SubjectApi>
  units: Array<UnitApi>
  topics: Array<TopicApi>
}

const passiveSlice = createSlice({
  name: 'passive',
  initialState: defaultState.passive,
  reducers: {
    appendNewInstructorCourse: (passive, action: PayloadAction<CourseApi>) => {
      return {
        ...passive,
        instructorCourses: [
          ...passive.instructorCourses,
          action.payload,
        ],
      };
    },
    updatePassiveInstructorCourse: (passive, action: PayloadAction<CourseApi>) => {
      return {
        ...passive,
        instructorCourses: passive.instructorCourses.map(course => {
          return course.id === action.payload.id
            ? action.payload
            : course;
        }),
      };
    },
    setPassiveData: (passive, action: PayloadAction<Passive>) => {
      return action.payload;
    },
    clear: () => {
      return defaultState.passive;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCommonPassiveData.fulfilled, (passive, action: PayloadAction<Pick<Passive, 'instructorCourses' | 'studentCourses' | 'products' | 'coinstructorsFromEnrolledCourses' | 'courseInstructors'>>) => {
      const {
        coinstructorsFromEnrolledCourses,
        courseInstructors,
        instructorCourses,
        products,
        studentCourses,
      } = action.payload;
      return {
        ...passive,
        coinstructorsFromEnrolledCourses,
        courseInstructors,
        instructorCourses,
        products,
        studentCourses,
      };
    });
    builder.addCase(loadInstructorPassiveData.fulfilled, (passive, action: PayloadAction<Pick<Passive, 'subjects' | 'units' | 'topics'>>) => {
      const {
        subjects,
        units,
        topics,
      } = action.payload;
      return {
        ...passive,
        subjects,
        units,
        topics,
      };
    });
    builder.addCase(updateCourse.fulfilled, (passive, action) => {
      return {
        ...passive,
        instructorCourses: passive.instructorCourses.map(course => {
          return course.id === action.payload.id
            ? action.payload
            : course;
        }),
      };
    });
    builder.addCase(createCourse.fulfilled, (passive, action) => {
      return {
        ...passive,
        instructorCourses: [
          ...passive.instructorCourses,
          action.payload,
        ],
      };
    });
    builder
      .addMatcher(
        (action: AnyAction) =>
          action.type.includes('/pending') && action.type.includes('/load'),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        (action: AnyAction) =>
          action.type.includes('/fulfilled') && action.type.includes('/load'),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        (action: AnyAction) =>
          action.type.includes('/rejected') && action.type.includes('/load'),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export default passiveSlice;
