/**
 * retrieveEnrolledCourses
 *
 * Aggregate both student and instructor courses and enrich them with metadata.
 * Used for displaying the CourseList, so we know instructor, co=instructor and the ways in which a student can launch a course
 *
 **/
import { createSelector } from '@reduxjs/toolkit';
import { Store } from 'types/store.types';
import { UserApi } from 'types/backend/users.types';
import { CourseApi } from 'types/backend/courses.types';
import { RoleEnum } from 'types/backend/roles.types';

export interface CourseWithMetadata extends CourseApi {
  metadata: {
    coinstructorUsers: Array<UserApi>
    enrolledAs: Array<RoleEnum>
    instructorUser?: UserApi
  }
}

export default createSelector(
  (store: Store) => store.passive.instructorCourses,
  (store: Store) => store.passive.studentCourses,
  (store: Store) => store.passive.courseInstructors,
  (store: Store) => store.passive.coinstructorsFromEnrolledCourses,
  (instructorCourses, studentCourses, courseInstructors = [], coinstructorsFromEnrolledCourses) => {
    const enrolledCourses: Array<CourseWithMetadata> = [];
    instructorCourses.forEach((course) => {
      const instructorUser = courseInstructors.find((courseInstructor) =>
        courseInstructor.courseId === course.id && courseInstructor.id === course.creatorUserId
      );
      const coinstructorUsers = coinstructorsFromEnrolledCourses.filter((coinstructor) => coinstructor.courseId === course.id);
      enrolledCourses.push({
        ...course,
        metadata: {
          enrolledAs: [RoleEnum.Instructor],
          instructorUser,
          coinstructorUsers,
        },
      });
    });
    studentCourses.forEach((course) => {
      // if course already exists in enrolledCourses, modify the enrolledAs array in place
      const existingInstructorCourse = enrolledCourses.find(({ id }) => id === course.id);
      if (existingInstructorCourse) {
        existingInstructorCourse.metadata.enrolledAs.push(RoleEnum.Student);
      } else {
        const instructorUser = courseInstructors.find((courseInstructor) => {
          return courseInstructor.courseId === course.id && courseInstructor.id === course.creatorUserId;
        });
        enrolledCourses.push({
          ...course,
          metadata: {
            coinstructorUsers: [],
            enrolledAs: [RoleEnum.Student],
            instructorUser,
          },
        });
      }
    });
    return enrolledCourses;
  }
);
