/* eslint @typescript-eslint/explicit-function-return-type: warn */
/**
 * This is the central container for all of our api calls to the feathers backend.
 * ALL calls to the server should route through this, it should be the primary abstraction layer between the backend and the frontend
**/

import axios, { AxiosRequestConfig } from 'axios';
import app, { addExtraQueryParams } from 'auth';
import type { AuthenticationResult } from '@feathersjs/authentication';

import { generateLogMessage, ErrorData } from 'utils/generateLogMessage';
import { saveLogMessage } from 'utils/saveLogMessage';
import logExecutionTime from 'utils/logExecutionTime';
import { AssessmentApiBase, SummativeAssessmentApi } from 'types/backend/assessments.types';
import { AssessmentInitApi } from 'types/backend/assessmentInit.types';
import { AssessmentQuestionApi } from 'types/backend/assessmentQuestions.types';
import { AssessmentQuestionSummaryDataLevel2Api, AssessmentSummaryData, AssessmentSummaryViewEnum } from 'types/backend/assessmentSummaryData.types';
import { AssessmentScoreSyncApi } from 'types/backend/assessmentScoreSync.types';
import { AuthorInitApi } from 'types/backend/authorInit.types';
import { ClassSessionApi } from 'types/backend/classSessions.types';
import { ClassSessionIclrApi } from 'types/backend/classSessionIclr.types';
import { ClassSessionLearningObjectiveApi } from 'types/backend/classSessionLearningObjectives.types';
import { ClassSessionOoclrApi } from 'types/backend/classSessionOoclr.types';
import { ClassSessionTopicApi } from 'types/backend/classSessionTopics.types';
import {
  ClrData,
  CreateAssessmentBody,
  CreateSummativeAssessmentBody,
  LogoutStatusEnum,
} from 'types/common.types';
import { CodonErrorCode } from 'types/backend/error.types';
import { CopyQuestionApi } from 'types/backend/copyQuestions.types';
import { CourseActivityCheckResponse } from 'types/backend/courseActivityCheck.types';
import { CourseApi } from 'types/backend/courses.types';
import { CourseAssessmentPresetApi } from 'types/backend/courseAssessmentPresets.types';
import { CourseHealthCheckResponseMulti } from 'types/backend/courseHealthCheck.types';
import { CreateUserLoApi } from 'instructor/controllers/Course/CourseLoSelectorController/CourseLoSelector.types';
import { DuplicateCourseApi } from 'types/backend/duplicateCourses.types';
import { EnrollmentApi } from 'types/backend/enrollments.types';
import { EnrollmentAssessmentApi } from 'types/backend/enrollmentAssessments.types';
import {
  GenericObject,
  LibraryTypeEnum,
  ResultsFormatEnum,
  YesNo,
  ContextMethod,
} from 'types/backend/shared.types';
import { IclrApi } from 'types/backend/iclr.types';
import { InstitutionApi } from 'types/backend/institutions.types';
import { L8yItem } from 'types/backend/l8yItems.types';
import { L8yItemStatus } from 'types/backend/l8y.types';
import { LearningObjectiveApi } from 'types/backend/learningObjectives.types';
import { OoclrApi } from 'types/backend/ooclr.types';
import { ProductApi } from 'types/backend/products.types';
import { QuestionApiOut, UserQuestionApi } from 'types/backend/questions.types';
import { QuestionGroupWithQuestionsApi } from 'types/backend/questionGroups.types';
import { QuestionLearningObjectiveApi } from 'types/backend/questionLearningObjectives.types';
import { StudentAssessmentApi } from 'types/backend/studentAssessments.types';
import { StudentAssessmentQuestionApi, StudentAssessmentQuestionApiWithSaqas } from 'types/backend/studentAssessmentQuestions.types';
import { StudentAssessmentQuestionAttemptApi } from 'types/backend/studentAssessmentQuestionAttempts.types';
import { StudentResponsesForAssessmentQuestionRes } from 'types/backend/studentResponses.types';
import { StudentRow } from 'types/backend/studentScoresData.types';
import { StudentStudyPathApi } from 'types/backend/studentStudyPaths.types';
import { StudentTopicCardReq, StudentTopicCardRes } from 'types/backend/studentTopicCards.types';
import { StudyPathApi } from 'types/backend/studyPaths.types';
import { SubjectApi } from 'types/backend/subjects.types';
import { SummativeAssessmentSupplementsApi } from 'types/backend/summativeAssessmentSupplements.types';
import {
  EnrollmentAndPayment,
  SaqaProjection,
  SaqasByTime,
  SystemReportsRequest,
} from 'types/backend/systemReports.types';
import { TopicApi } from 'types/backend/topics.types';
import { UnitApi } from 'types/backend/units.types';
import { UserApi } from 'types/backend/users.types';
import { WorkerPipelineApi } from 'types/backend/workerPipelines.types';

const apiUrl = process.env.REACT_APP_API_NEXT_URL;
const accessTokenDuration = Number(process.env.REACT_APP_ACCESS_TOKEN_DURATION) || 60;
const defaultProductId = Number(process.env.REACT_APP_DEFAULT_PRODUCT_ID) || 1;

const limits = {
  'paginate': {
    'default': 500,
    'max': 1000,
  },
  'bigPaginate': {
    'default': 1000,
    'max': 1000,
  },
  'biggestPaginate': {
    'default': 5000,
    'max': 5000,
  },
};

interface PaginatedResponse<T> {
  total: number
  limit: number
  skip: number
  data: Array<T>
}
export interface SimplifiedErrorResponse {
  error: {
    message: string
    code: number
    name: string
    errorCode?: CodonErrorCode
  }
}

export type ErrorableResponse<T> = T | SimplifiedErrorResponse

class ApiNext {
  _token: string | null;
  _user: UserApi | null;
  _refreshToken: string | null;
  _accessExp: number;
  _userId: string | null;
  _masqueradeUserId: string | null;
  constructor() {
    const existingToken = window.localStorage.getItem('token');
    const existingRefreshToken = window.localStorage.getItem('refreshToken');
    const existingAccessExp = window.localStorage.getItem('accessExp');
    const existingUser = window.localStorage.getItem('user');
    const existingMasqueradeUserId = window.localStorage.getItem('masqueradeUserId');
    this._token = existingToken ?? null;
    this._user = existingUser ? JSON.parse(existingUser) : null;
    this._refreshToken = existingRefreshToken ?? null;
    this._accessExp = Number(existingAccessExp) ?? 0;
    this._userId = existingUser ? JSON.parse(existingUser).id : null;
    this._masqueradeUserId = existingMasqueradeUserId ?? null;

    axios.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error: ErrorData) => {
        if (error?.response?.status === 401) {
          console.debug('401 intercepted', error.response);
        } else if (this.user) {
          // can send logs only if user authenticated
          // any API error will be send to log shipper
          // ignore errors from log-shipper
          const logMessage: string = generateLogMessage(error);
          if (!logMessage.includes('log-shippers')) {
            await saveLogMessage(logMessage);
          }
        }
        return Promise.reject(error);
      });
  }

  set token(val) {
    this._token = val;
    if (val) {
      window.localStorage.setItem('token', val);
    }
  }

  get token(): string | null {
    return this._token;
  }

  set accessExp(val) {
    this._accessExp = val;
    if (val) {
      window.localStorage.setItem('accessExp', val.toString());
    }
  }

  get accessExp(): number {
    return this._accessExp;
  }

  set refreshToken(val) {
    this._refreshToken = val;
    if (val) {
      window.localStorage.setItem('refreshToken', val);
    }
  }

  get refreshToken(): string | null {
    return this._refreshToken;
  }

  set user(val) {
    this._user = val;
    window.localStorage.setItem('user', JSON.stringify(val));
  }

  get user(): UserApi | null {
    return this._user;
  }

  set userId(val) {
    this._userId = val;
    if (val) {
      window.localStorage.setItem('userId', val);
    }
  }

  get userId(): string | null {
    return this._userId;
  }

  set masqueradeUserId(val) {
    this._masqueradeUserId = val;
    if (val) {
      window.localStorage.setItem('masqueradeUserId', val);
    } else {
      window.localStorage.removeItem('masqueradeUserId');
    }
  }

  get masqueradeUserId(): string | null {
    return this._masqueradeUserId;
  }

  async ltiLaunch(): Promise<{ courseId: string; email: string; ltiUserId: string } | false> {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const launchToken = urlParams.get('launchToken');

    const checkLtiSession = await axios
      .get(`${apiUrl}/lti13?launchToken=${launchToken}`, { withCredentials: true })
      .then((res) => {
        if (res?.data && Object.keys(res.data).length === 0) {
          throw new Error(`lti-log FE: lti13 returned res.data is an empty object. ${JSON.stringify(res)}`);
        }
        window.sessionStorage.setItem('lti', JSON.stringify(res.data));
        const { course_id: courseId, sub: ltiUserId, ...rest } = res.data;
        return {
          ...rest,
          courseId,
          ltiUserId,
        };
      })
      .catch(async (err) => {
        await saveLogMessage(err.message);
        console.error('ltiLaunch catch', err);
        return false;
      });
    return checkLtiSession;
  }
  // _baseRequest should never be called directly, use one of the below variants
  async _baseRequest<T>(payload: AxiosRequestConfig): Promise<T> {
    payload.url = `${apiUrl}${payload.url}`;

    const now = Math.round(Date.now() / 1000);
    // if expiration is close to zero, still good practice to refresh beforehand to count network delays and etc
    if (!payload.url.includes('authmanagement') && (now > this.accessExp || (this.accessExp - now < 30))) {
      await this.reauth();
    }

    if (this.token) {
      payload.headers = {
        Authorization: `Bearer ${this.token}`,
      };
      if (this.masqueradeUserId) {
        payload.headers['x-masquerade-as'] = this.masqueradeUserId;
      }
      if (this.userId) {
        payload.headers['x-user-id'] = this.userId;
      }
    }
    const response = await axios(payload);
    // TODO: call logout if HTTP status code is 401 and redirect to something relevant
    console.debug('axios response', response, payload);
    const { data } = response;
    return data;
  }
  // Simple request method, errors unhandled
  async _request<T = unknown>(payload: AxiosRequestConfig): Promise<T> {
    try {
      return await this._baseRequest<T>(payload);
    } catch (error: any) {
      // get message from backend response
      // NOTE: when you stringify error you don't get the response object, you have to invoke it specifically
      const beErrorMessage = error.response?.data?.message;
      console.error(`_request error ${JSON.stringify({ beErrorMessage, payload, error })}`);
      throw error;
    }
  }
  // Use this to get paginated data, where the API will return a `PaginatedResponse` object with the desired array as `data` alongside limit, skip, total
  async _requestPaginated<T = unknown>(payload: AxiosRequestConfig): Promise<PaginatedResponse<T>> {
    try {
      return await this._baseRequest<PaginatedResponse<T>>(payload);
    } catch (error: any) {
      console.error(`_requestPaginated error ${JSON.stringify({ payload, error })}`);
      throw error;
    }
  }
  // use this when handling errors in UI, returns the error object in the root object for handling different CodonErrorCodes
  async _requestWithErrorHandling<T = unknown>(payload: AxiosRequestConfig): Promise<ErrorableResponse<T>> {
    try {
      return await this._baseRequest<T>(payload);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        const axiosError = error;

        // simplify returned error data, put errorCode at the root of the error object
        // extra safety on these destructs to prevent TypeErrors
        const { data } = axiosError.response || {};
        const { data: nestedData, message } = data || {};
        const { errorCode } = nestedData || {};
        // TODO?: clean up error object on backend so that we don't have `data.data.errorCode`
        console.error(`_requestWithErrorHandling error: ${message} - ${JSON.stringify(axiosError.response)}`);
        // explicitly typing this response for safety instead of coercing with `as SimplifiedErrorResponse`
        const simplifiedError: SimplifiedErrorResponse = {
          error: {
            ...data,
            errorCode,
          },
        };
        return simplifiedError;
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`_requestWithErrorHandling error did not return response.data ${JSON.stringify({ error, payload })}`);
      }
    }
  }

  /**
   * Redirect to logout so that we can clear redux state when logging out, which doesn't happen with this.logout()
   * @returns void
   */
  redirectLogout(): void {
    const isLtiUser = !!this.user?.ltiUserId;
    window.localStorage.removeItem('user');
    window.location.assign(`/logout?status=${isLtiUser ? LogoutStatusEnum.IdleLogoutLti : LogoutStatusEnum.IdleLogout}`);
  }

  /**
   * Update access token if its expired
   * @returns Promise
   */
  async reauth(): Promise<void> {
    try {
      if (!(this.refreshToken && this.userId)) {
        console.debug('login page redirect');
        await this.logout();
        return;
      }

      const payload = {
        id: this.userId,
        refreshToken: this.refreshToken || 'null',
      };

      await axios.post(`${apiUrl}/refresh-tokens`, payload).then((result) => {
        this.token = result.data.accessToken;
        const futureExp = Math.round(new Date(Date.now() + accessTokenDuration * 60 * 1000).getTime() / 1000);
        this.accessExp = futureExp;
      }).catch((err) => {
        console.warn('reauth failed, redirecting', err);
        this.redirectLogout();
        return;
      });


    } catch (error: any) {
      console.error(error.message);
      throw error;
    }
  }

  /**
   * Revoke refresh token so access token can't be updated
   * @returns Promise
   */
  async revokeToken(): Promise<void> {
    try {
      if (!!this.refreshToken) {
        await this._request({
          method: 'patch',
          url: '/refresh-tokens',
          data: {
            refreshToken: this.refreshToken,
          },
        });
      }
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('token');
      localStorage.removeItem('accessExp');
    } catch (error: any) {
      console.error('revokeToken catch', error.message);
      // Don't throw here, since failures need to fall-through on auto-logout
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('token');
      localStorage.removeItem('accessExp');
    }
  }

  /************************************************************************
   * Login/Register/Enrollment
   * registration interface doesn't exist yet, create user via API call
   *************************************************************************/
  async registerUser(email: string, username: string, password: string): Promise<UserApi> {
    try {
      return await this._request<UserApi>({
        method: 'post',
        url: `${apiUrl}/users/`,
        data: {
          email,
          username,
          password,
        },
      });
    } catch (err) {
      console.error('registerUser error', err);
      throw err;
    }
  }

  onLoginSuccess({
    accessToken,
    authentication,
    refreshToken,
    user,
  }: AuthenticationResult): void {
    this.token = accessToken;
    this.refreshToken = refreshToken;
    this.accessExp = authentication?.payload?.exp ?? 0;
    this.user = user;
    this.userId = user.id;
  }

  // Login and get token/user
  async login(un: string, pwd: string): Promise<UserApi | false> {
    this.token = null;
    this.masqueradeUserId = null;
    return await app.authenticate({
      strategy: 'local',
      email: un,
      password: pwd,
    }).then((authResult) => {
      const { accessToken, user } = authResult;
      if (user && accessToken) {
        this.onLoginSuccess(authResult);
        return user;
      } else {
        return false;
      }
    });
  }

  async ltiLogin(un: string, ltiUserId: string): Promise<UserApi | false> {
    this.token = null;
    this.masqueradeUserId = null;
    if (ltiUserId) {
      addExtraQueryParams({ ltiUserId });
    }
    return await app.authenticate({
      strategy: 'lti',
      email: un,
    }).then((authResult) => {
      const { accessToken, user } = authResult;
      const interceptorId = window.sessionStorage.getItem('interceptorId');
      if (interceptorId) {
        axios.interceptors.request.eject(Number(interceptorId));
        window.sessionStorage.removeItem('interceptorId');
      }
      if (user && accessToken) {
        this.onLoginSuccess(authResult);
        return user;
      } else {
        return false;
      }
    });
  }

  async mfaLogin(token: string, userId: string): Promise<UserApi | false> {
    const authResult = await this._request<Promise<AuthenticationResult | false>>({
      method: 'post',
      url: '/authentication',
      data: {
        strategy: 'mfa',
        token,
        userId,
      },
    });

    const { accessToken, refreshToken, authentication, user } = authResult || {};
    if (accessToken) {
      this.onLoginSuccess({
        accessToken,
        authentication,
        refreshToken,
        user,
      });
      return user as UserApi;
    }
    return false;
  }

  // logout the user
  async logout(): Promise<AuthenticationResult | null> {
    return await app.logout();
  }

  async getUsersByCourseAndRole(courseId: string, roleId: number): Promise<Array<UserApi>> {
    const query = `courseId=${courseId}&enrollmentRoleId=${roleId}&$sort[last_name]=1&$sort[first_name]=1&$limit=${limits.bigPaginate.max}`;
    const { data } = await this._requestPaginated<UserApi>({
      method: 'get',
      url: `/users?${query}`,
    });
    return data;
  }
  async getUsersByCoursesAndRole(courseIds: Array<string>, roleId: number): Promise<Array<UserApi>> {
    if (!courseIds.length) {
      return [];
    }
    const query = `courseId[$in][]=${courseIds.join('&courseId[$in][]=')}&enrollmentRoleId=${roleId}&$sort[last_name]=1&$sort[first_name]=1&$limit=${limits.bigPaginate.max}`;
    const { data } = await this._requestPaginated<UserApi>({
      method: 'get',
      url: `/users?${query}`,
    });
    return data;
  }

  async getNonAdminUsersByEmail(email: string): Promise<PaginatedResponse<UserApi>> {
    return await this._requestPaginated<UserApi>({
      method: 'get',
      url: `/users?roleId[$ne]=4&$select[]=id&$select[]=first_name&$select[]=last_name&$select[]=email&$select[]=system_role__id&$select[]=lti_user_id&$select[]=agreement_date&$select[]=special_user_type&email[$ilike]=${encodeURIComponent(email)}&$sort[system_role__id]=1`,
    });
  }

  async getNonAdminUsersByName(searchString: string): Promise<PaginatedResponse<UserApi>> {
    return await this._requestPaginated<UserApi>({
      method: 'get',
      url: `/users?roleId[$ne]=4&$select[]=id&$select[]=first_name&$select[]=last_name&$select[]=email&$select[]=system_role__id&$select[]=lti_user_id&$select[]=agreement_date&$select[]=special_user_type${searchString}&$sort[system_role__id]=1`,
    });
  }

  async getNonAdminUserById(userId: string): Promise<PaginatedResponse<UserApi>> {
    return await this._requestPaginated<UserApi>({
      method: 'get',
      url: `/users?roleId[$ne]=4&$select[]=id&$select[]=first_name&$select[]=last_name&$select[]=email&$select[]=system_role__id&$select[]=lti_user_id&$select[]=agreement_date&$select[]=special_user_type&id=${userId}&$sort[system_role__id]=1`,
    });
  }

  async editEnrollment(enrollmentId: number, data: Pick<EnrollmentApi, 'id' | 'firstAccessedAt'>): Promise<EnrollmentApi> {
    return await this._request<EnrollmentApi>({
      method: 'patch',
      url: `/enrollments/${enrollmentId}`,
      data,
    });
  }

  // Get all courses the user is enrolled in
  async getUserEnrollments(userId: string): Promise<Array<EnrollmentApi>> {
    const user = this.masqueradeUserId || userId;
    const { data } = await this._requestPaginated<EnrollmentApi>({
      method: 'get',
      url: `/enrollments?userId=${user}`,
    });
    return data;
  }
  async getUserEnrollmentsByCourseId(userId: string, courseId: string): Promise<Array<EnrollmentApi>> {
    const user = this.masqueradeUserId || userId;
    const { data } = await this._requestPaginated<EnrollmentApi>({
      method: 'get',
      url: `/enrollments?userId=${user}&courseId=${courseId}`,
    });
    return data;
  }

  //get all enrollments in a course for a specific roleId
  async getCourseEnrollmentsByRole(courseId: string, roleId: number): Promise<Array<EnrollmentApi>> {
    const { data } = await this._requestPaginated<EnrollmentApi>({
      method: 'get',
      url: `/enrollments?courseId=${courseId}&roleId=${roleId}&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  /**
   * Access Codes & Payment
   **/
  async launchPayment(enrollmentId: number): Promise<{ url: string }> {
    return await this._request<{ url: string }>({
      method: 'post',
      url: '/payments',
      data: {
        enrollmentId,
      },
    });
  }

  async validateAccessCode(accessCode: string, enrollmentId: number): Promise<string> {
    return await this._request<string>({
      method: 'post',
      url: '/payments',
      data: {
        accessCode,
        enrollmentId,
      },
    });
  }

  /**
   * get products
   */
  async getProducts(): Promise<Array<ProductApi>> {
    const { data } = await this._requestPaginated<ProductApi>({
      method: 'get',
      url: '/products?$sort[title]=1',
    });
    return data.reduce((acc: Array<ProductApi>, product) => {
      // default product first
      if (product.id === defaultProductId) {
        return [product, ...acc];
      }
      return [...acc, product];
    }, []);
  }


  /**
   * Course data
   */
  async createCourse(data: Omit<CourseApi, 'id' | 'createdAt' | 'updatedAt'>): Promise<CourseApi> {
    return await this._request<CourseApi>({
      method: 'post',
      url: '/courses',
      data: {
        ...data,
        creatorUserId: this.masqueradeUserId || data.creatorUserId,
      },
    });
  }
  async getCourse(courseId: string): Promise<ErrorableResponse<CourseApi>> {
    return await this._requestWithErrorHandling<CourseApi>({
      method: 'get',
      url: `/courses/${courseId}`,
    });
  }

  /***
   * get a list of courses from an array of ids
   * sorted by start date
   */
  async getCourses(courseIds: Array<string>): Promise<Array<CourseApi>> {
    if (!courseIds || !courseIds.length) {
      return [];
    }
    const query = `id[$in][]=${courseIds.join('&id[$in][]=')}&$sort[start_date]=1`;
    const { data } = await this._requestPaginated<CourseApi>({
      method: 'get',
      url: `/courses?${query}`,
    });
    return data;
  }

  async editCourse(courseId: string, data: CourseApi): Promise<CourseApi> {
    return await this._request<CourseApi>({
      method: 'put',
      url: `/courses/${courseId}`,
      data,
    });
  }

  /**
   * getInitialData() calls
   * This set of calls propagates the initial state of of the frontend
   */
  async getCourseAssessmentPreset(courseId: string): Promise<CourseAssessmentPresetApi> {
    return await this._request<CourseAssessmentPresetApi>({
      method: 'get',
      url: `/course-assessment-presets/${courseId}`,
    });
  }

  async editCourseAssessmentPreset(preset: CourseAssessmentPresetApi): Promise<CourseAssessmentPresetApi> {
    return await this._request<CourseAssessmentPresetApi>({
      method: 'put',
      url: `/course-assessment-presets/${preset.id}`,
      data: preset,
    });
  }

  // formerly getTopicOnes [apiNext]
  async getSubjects(): Promise<Array<SubjectApi>> {
    const { data } = await this._requestPaginated<SubjectApi>({
      method: 'get',
      url: '/subjects?$sort[name]=1',
    });
    return data;
  }

  // formerly getTopicTwos [apiNext]
  async getUnits(): Promise<Array<UnitApi>> {
    const { data } = await this._requestPaginated<UnitApi>({
      method: 'get',
      url: `/units?type=template&$sort[subject__id]=1&$sort[order]=1&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  // formerly getTopicOnes [apiNext]
  async getTopics(): Promise<Array<TopicApi>> {
    const { data } = await this._requestPaginated<TopicApi>({
      method: 'get',
      url: `/topics?type=template&$sort[subject__id]=1&$sort[unit__id]=1&$sort[order]=1&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  /**
   * Class Sessions
   */

  async getSortedCourseClassSessions(courseId: string): Promise<Array<ClassSessionApi>> {
    const { data } = await this._requestPaginated<ClassSessionApi>({
      method: 'get',
      url: `/class-sessions?courseId=${courseId}&$sort[class_date]=1`,
    });
    return data;
  }

  async editClassSession(classSessionId: number, data: ClassSessionApi): Promise<ClassSessionApi> {
    return await this._request<ClassSessionApi>({
      method: 'put',
      url: `/class-sessions/${classSessionId}`,
      data,
    });
  }

  /**
   * Course and classSession Learning Objectives
   */
  async createClassSessionLo(data: Omit<ClassSessionLearningObjectiveApi, 'id' | 'updatedAt' | 'createdAt'>): Promise<ClassSessionLearningObjectiveApi> {
    return await this._request<ClassSessionLearningObjectiveApi>({
      method: 'post',
      url: '/class-session-learning-objectives',
      data,
    });
  }

  async editClassSessionLo(data: ClassSessionLearningObjectiveApi): Promise<ErrorableResponse<ClassSessionLearningObjectiveApi>> {
    const { id } = data;
    return await this._requestWithErrorHandling<ClassSessionLearningObjectiveApi>({
      method: 'put',
      url: `/class-session-learning-objectives/${id}`,
      data,
    });
  }

  async getClassSessionLosByCourseId(courseId: string): Promise<Array<ClassSessionLearningObjectiveApi>> {
    const { data } = await this._requestPaginated<ClassSessionLearningObjectiveApi>({
      method: 'get',
      url: `/class-session-learning-objectives?courseId=${courseId}&$sort[class_session__id]=1&$sort[order]=1`,
    });
    return data;
  }

  async getClassSessionLosForClassSessionIds(classSessionIds: Array<number>): Promise<Array<ClassSessionLearningObjectiveApi>> {
    if (classSessionIds.length === 0) {
      return [];
    }
    const query = `class_session__id[$in][]=${classSessionIds.join('&class_session__id[$in][]=')}&$sort[class_session__id]=1&$sort[order]=1`;
    const { data } = await this._requestPaginated<ClassSessionLearningObjectiveApi>({
      method: 'get',
      url: `/class-session-learning-objectives?${query}`,
    });
    return data;
  }

  async deleteClassSessionLo(classSessionLoId: number): Promise<ClassSessionLearningObjectiveApi> {
    return await this._request({
      method: 'delete',
      url: `/class-session-learning-objectives/${classSessionLoId}`,
    });
  }

  // formerly getTemplateLosByTopicOneId
  async getTemplateLosBySubjectId(subjectId: number): Promise<Array<LearningObjectiveApi>> {
    const { data } = await this._requestPaginated<LearningObjectiveApi>({
      method: 'get',
      url: `/learning-objectives?subjectId=${subjectId}&type=template&$sort[unit__id]=1&$sort[topic__id]=1&$sort[order]=1&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  async getLosByLoIds(loIds: Array<number>): Promise<Array<LearningObjectiveApi>> {
    const query = `id[$in][]=${loIds.join('&id[$in][]=')}&$sort[id]=1&$limit=${limits.bigPaginate.max}`;
    const { data } = await this._requestPaginated<LearningObjectiveApi>({
      method: 'get',
      url: `/learning-objectives?${query}`,
    });
    return data;
  }

  /**
   * Assessments
   */
  async createAssessment(data: CreateAssessmentBody | CreateSummativeAssessmentBody): Promise<AssessmentApiBase | SummativeAssessmentApi> {
    // heritage console log preserved by the Codon Historical Society
    console.debug('Arrg, the pirates be watchin me console.logs :) ', data);
    // omit the temp id when sending assessment data
    return await this._request<AssessmentApiBase | SummativeAssessmentApi>({
      method: 'post',
      url: '/assessments',
      data: {
        ...data,
        userId: this.masqueradeUserId || this._userId,
      },
    });
  }
  async editStudyPath(studyPathId: number, assessmentIds: Array<string>): Promise<StudyPathApi> {
    // omit the temp id when sending assessment data
    return await this._request<StudyPathApi>({
      method: 'patch',
      url: `/study-paths/${studyPathId}`,
      data: { assessmentIds },
    });
  }

  async getSummativeAssessmentSupplements(summativeIds: Array<string>): Promise<Array<SummativeAssessmentSupplementsApi>> {
    if (summativeIds.length === 0) {
      return [];
    }
    const query = `id[$in][]=${summativeIds.join('&id[$in][]=')}&$sort[id]=1`;
    const { data } = await this._requestPaginated<SummativeAssessmentSupplementsApi>({
      method: 'get',
      url: `/summative-assessment-supplements?${query}`,
    });
    return data;
  }

  async getAssessmentsByIds(assessmentIds: Array<string>): Promise<Array<AssessmentApiBase | SummativeAssessmentApi>> {
    const query = `id[$in][]=${assessmentIds.join('&id[$in][]=')}&$sort[id]=1`;
    const { data } = await this._requestPaginated<AssessmentApiBase | SummativeAssessmentApi>({
      method: 'get',
      url: `/assessments?${query}`,
    });
    return data;
  }

  async getAssessmentsByCourseId(courseId: string): Promise<Array<AssessmentApiBase | SummativeAssessmentApi>> {
    const { data } = await this._requestPaginated<AssessmentApiBase | SummativeAssessmentApi>({
      method: 'get',
      url: `/assessments?courseId=${courseId}&$sort[due_date]=1`,
    });
    return data;
  }

  async editAssessment(id: string, data: AssessmentApiBase | SummativeAssessmentApi): Promise<AssessmentApiBase | SummativeAssessmentApi> {
    return await this._request<AssessmentApiBase | SummativeAssessmentApi>({
      method: 'put',
      url: `/assessments/${id}`,
      data,
    });
  }

  async deleteAssessment(id: string): Promise<AssessmentApiBase> {
    return await this._request({
      method: 'delete',
      url: `/assessments/${id}`,
    });
  }

  /**
   * Instructions: Iclr & Ooclr
   */
  async getClassSessionIclr(classSessionsIds: Array<number>): Promise<Array<ClassSessionIclrApi>> {
    if (!classSessionsIds.length) {
      return [];
    }
    const query = `classSessionId[$in][]=${classSessionsIds.join('&classSessionId[$in][]=')}&$sort[order]=1`;
    const { data } = await this._requestPaginated<ClassSessionIclrApi>({
      method: 'get',
      url: `/class-session-iclr?${query}`,
    });
    return data;
  }

  async getClassSessionOoclr(classSessionsIds: Array<number>): Promise<Array<ClassSessionOoclrApi>> {
    if (!classSessionsIds.length) {
      return [];
    }
    const query = `classSessionId[$in][]=${classSessionsIds.join('&classSessionId[$in][]=')}&$sort[order]=1`;
    const { data } = await this._requestPaginated<ClassSessionOoclrApi>({
      method: 'get',
      url: `/class-session-ooclr?${query}`,
    });
    return data;
  }

  async getIclrsByIds(iclrIds: Array<number>): Promise<Array<IclrApi>> {
    if (!iclrIds.length) {
      return [];
    }
    const query = `id[$in][]=${iclrIds.join('&id[$in][]=')}&$limit=${limits.paginate.max}`;
    const { data } = await this._requestPaginated<IclrApi>({
      method: 'get',
      url: `/iclr?${query}`,
    });
    return data;
  }

  async getOoclrsByIds(ooclrIds: Array<number>): Promise<Array<OoclrApi>> {
    if (!ooclrIds.length) {
      return [];
    }
    const query = `id[$in][]=${ooclrIds.join('&id[$in][]=')}&$limit=${limits.paginate.max}`;
    const { data } = await this._requestPaginated<OoclrApi>({
      method: 'get',
      url: `/ooclr?${query}`,
    });
    return data;
  }

  async createIclr(iclrData: ClrData): Promise<IclrApi> {
    return await this._request<IclrApi>({
      method: 'post',
      url: '/iclr',
      data: iclrData,
    });
  }

  async createOoclr(ooclrData: ClrData): Promise<OoclrApi> {
    return await this._request<OoclrApi>({
      method: 'post',
      url: '/ooclr',
      data: ooclrData,
    });
  }

  async createClassSessionIclr(classSessionId: number, iclrId: number): Promise<ClassSessionIclrApi> {
    return await this._request<ClassSessionIclrApi>({
      method: 'post',
      url: '/class-session-iclr',
      data: {
        classSessionId,
        iclrId,
      },
    });
  }

  async createClassSessionOoclr(classSessionId: number, ooclrId: number): Promise<ClassSessionOoclrApi> {
    return await this._request<ClassSessionOoclrApi>({
      method: 'post',
      url: '/class-session-ooclr',
      data: {
        classSessionId,
        ooclrId,
      },
    });
  }

  async editIclr(iclrData: IclrApi): Promise<IclrApi> {
    return await this._request<IclrApi>({
      method: 'put',
      url: `/iclr/${iclrData.id}`,
      data: iclrData,
    });
  }

  async editOoclr(ooclrData: OoclrApi): Promise<OoclrApi> {
    return await this._request<OoclrApi>({
      method: 'put',
      url: `/ooclr/${ooclrData.id}`,
      data: ooclrData,
    });
  }

  async deleteIclr(id: number): Promise<IclrApi> {
    return await this._request({
      method: 'delete',
      url: `/iclr/${id}`,
    });
  }

  async deleteOoclr(id: number): Promise<OoclrApi> {
    return await this._request({
      method: 'delete',
      url: `/ooclr/${id}`,
    });
  }

  async deleteClassSessionIclr(id: number): Promise<ClassSessionIclrApi> {
    return await this._request({
      method: 'delete',
      url: `/class-session-iclr/${id}`,
    });
  }

  async deleteClassSessionOoclr(id: number): Promise<ClassSessionOoclrApi> {
    return await this._request({
      method: 'delete',
      url: `/class-session-ooclr/${id}`,
    });
  }

  /**
   * User Questions
   */
  async createUserQuestionLos(data: Array<Pick<QuestionLearningObjectiveApi, 'questionId' | 'learningObjectiveId' | 'type' | 'userId'>>): Promise<Array<QuestionLearningObjectiveApi>> {
    return await this._request<Array<QuestionLearningObjectiveApi>>({
      method: 'post',
      url: '/question-learning-objectives',
      data,
    });
  }
  async deleteUserQuestionLo(qLoId: number): Promise<QuestionLearningObjectiveApi> {
    return await this._request<QuestionLearningObjectiveApi>({
      method: 'delete',
      url: `/question-learning-objectives/${qLoId}`,
    });
  }
  async createUserQuestion(data: UserQuestionApi): Promise<ErrorableResponse<QuestionApiOut>> {
    return await this._requestWithErrorHandling<QuestionApiOut>({
      method: 'post',
      url: '/questions',
      data,
    });
  }

  async getQuestionGroups(): Promise<Array<QuestionGroupWithQuestionsApi>> {
    const { data } = await this._requestPaginated<QuestionGroupWithQuestionsApi>({
      method: 'get',
      url: `/question-groups?$sort[subject__id]=1&$sort[id]=1&allData=${YesNo.Yes}`,
    });
    return data;
  }
  async getQuestionLos(questionId: number, userIds: Array<string>): Promise<Array<QuestionLearningObjectiveApi>> {
    const userIdString = userIds.map((uid, idx) => `&$or[${idx + 1}][user__id]=${uid}`).join('');
    const { data } = await this._requestPaginated<QuestionLearningObjectiveApi>({
      method: 'get',
      url: `/question-learning-objectives?$sort[type]=1&questionId=${questionId}&$or[0][type]=template${userIdString}&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }
  async getCourseQuestions(courseId: string): Promise<Array<QuestionApiOut>> {
    const { data } = await this._requestPaginated<QuestionApiOut>({
      method: 'get',
      url: `/questions?courseId=${courseId}`,
    });
    return data;
  }
  async editUserQuestion(l8yRef: string, data: UserQuestionApi): Promise<ErrorableResponse<QuestionApiOut>> {
    const result = await this._requestWithErrorHandling<QuestionApiOut>({
      method: 'put',
      url: `/questions/${l8yRef}`,
      data,
    });
    return result;
  }
  async getUserQuestionsBySubjectId(userId: string, subjectId: number): Promise<Array<QuestionApiOut>> {
    const { data } = await this._requestPaginated<QuestionApiOut>({
      method: 'get',
      url: `/questions?subjectId=${subjectId}&type=${LibraryTypeEnum.User}&userId=${userId}&$limit=${limits.biggestPaginate.max}`,
    });
    return data;
  }

  async createUserLo(data: CreateUserLoApi): Promise<LearningObjectiveApi> {
    const userId = this.masqueradeUserId || this.userId;
    return await this._request<LearningObjectiveApi>({
      method: 'post',
      url: '/learning-objectives',
      data: {
        ...data,
        userId,
        type: LibraryTypeEnum.User,
      },
    });
  }

  async editUserLo(data: LearningObjectiveApi): Promise<LearningObjectiveApi> {
    return await this._request<LearningObjectiveApi>({
      method: 'put',
      url: `/learning-objectives/${data.id}`,
      data,
    });
  }

  async getAssessmentQuestionMaps(assessments: Array<string>): Promise<Array<AssessmentQuestionApi>> {
    if (!assessments.length) {
      return [] as Array<AssessmentQuestionApi>;
    }
    const query = `assessmentId[$in][]=${assessments.join('&assessmentId[$in][]=')}&$sort[assessment__id]=1&$sort[order]=1`;
    const { data } = await this._requestPaginated<AssessmentQuestionApi>({
      method: 'get',
      url: `/assessment-questions?${query}`,
    });
    return data;
  }

  async getAssessmentQuestionMapsByQuestionIds(questionIds: Array<number>): Promise<Array<AssessmentQuestionApi>> {
    if (!questionIds.length) {
      return [];
    }
    const query = `questionId[$in][]=${questionIds.join('&questionId[$in][]=')}`;
    const { data } = await this._requestPaginated<AssessmentQuestionApi>({
      method: 'get',
      url: `/assessment-questions?${query}`,
    });
    return data;
  }

  async createAssessmentQuestionMap(data: Omit<AssessmentQuestionApi, 'id' | 'createdAt' | 'updatedAt'>): Promise<AssessmentQuestionApi> {
    return await this._request<AssessmentQuestionApi>({
      method: 'post',
      url: '/assessment-questions',
      data,
    });
  }

  async createAssessmentQuestionMaps(data: Pick<AssessmentQuestionApi, 'assessmentId' | 'questionIds'>): Promise<Array<AssessmentQuestionApi>> {
    return await this._request<Array<AssessmentQuestionApi>>({
      method: 'post',
      url: '/assessment-questions',
      data,
    });
  }
  async editAssessmentQuestionMap(id: number, data: AssessmentQuestionApi): Promise<AssessmentQuestionApi> {
    return await this._request<AssessmentQuestionApi>({
      method: 'put',
      url: `/assessment-questions/${id}`,
      data,
    });
  }
  async deleteAssessmentQuestionMap(id: number): Promise<ErrorableResponse<AssessmentQuestionApi>> {
    return await this._requestWithErrorHandling<AssessmentQuestionApi>({
      method: 'delete',
      url: `/assessment-questions/${id}`,
    });
  }
  async deleteAssessmentQuestionMaps(aqmIds: Array<number>): Promise<ErrorableResponse<Array<AssessmentQuestionApi>>> {
    const query = `id[$in][]=${aqmIds.join('&id[$in][]=')}`;
    return await this._requestWithErrorHandling<Array<AssessmentQuestionApi>>({
      method: 'delete',
      url: `/assessment-questions?${query}`,
    });
  }

  async getTemplateQuestionsBySubjectIdWithQloUserIds(subjectId: number, qloUserIds: Array<string>): Promise<Array<QuestionApiOut>> {
    const qloQuery = `&qloUserId[$in][]=${qloUserIds.join('&qloUserId[$in][]=')}`;
    const { data } = await this._requestPaginated<QuestionApiOut>({
      method: 'get',
      url: `/questions?type=template&subjectId=${subjectId}${qloQuery}&$sort[l8y_id]=1&$limit=${limits.biggestPaginate.max}`,
    });
    return data;
  }

  async getUserUnitsBySubjectsAndUsers(subjectIds: Array<number>, userIds: Array<string>): Promise<Array<UnitApi>> {
    const subjectQuery = `subjectId[$in][]=${subjectIds.join('&subjectId[$in][]=')}`;
    const userIdQuery = `&userId[$in][]=${userIds.join('&userId[$in][]=')}`;
    const { data } = await this._requestPaginated<UnitApi>({
      method: 'get',
      url: `/units?${subjectQuery}&type=user${userIdQuery}&$sort[subject__id]=1&$sort[user__id]=1&$sort[order]=1&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  async createUserTopic(data: Omit<TopicApi, 'id'>): Promise<TopicApi> {
    return await this._request<TopicApi>({
      method: 'post',
      url: '/topics',
      data: {
        ...data,
      },
    });
  }

  async editUserTopic(userTopicId: number, data: TopicApi): Promise<TopicApi> {
    return await this._request<TopicApi>({
      method: 'put',
      url: `/topics/${userTopicId}`,
      data,
    });
  }

  async getUserTopicsBySubjectAndUser(subjectId: number, userId: string): Promise<Array<TopicApi>> {
    const { data } = await this._requestPaginated<TopicApi>({
      method: 'get',
      url: `/topics?subjectId=${subjectId}&type=user&userId=${userId}&$sort[unit__id]=1&$sort[order]=1&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  async getTopicsByIds(topics: Array<number>): Promise<Array<TopicApi>> {
    if (!topics.length) {
      return [];
    }
    const query = `id[$in][]=${topics.join('&id[$in][]=')}&$sort[order]=1&$limit=${limits.bigPaginate.max}`;
    const { data } = await this._requestPaginated<TopicApi>({
      method: 'get',
      url: `/topics?${query}`,
    });
    return data;
  }

  async getUserLosBySubjectAndUser(subjectId: number, userId: string): Promise<Array<LearningObjectiveApi>> {
    const { data } = await this._requestPaginated<LearningObjectiveApi>({
      method: 'get',
      url: `/learning-objectives?subjectId=${subjectId}&type=user&userId=${userId}&$sort[topic__id]=1&$sort[order]=1&$limit=${limits.bigPaginate.max}`,
    });
    return data;
  }

  async createClassSessionTopic(data: Omit<ClassSessionTopicApi, 'id' | 'createdAt' | 'updatedAt'>): Promise<ClassSessionTopicApi> {
    return await this._request<ClassSessionTopicApi>({
      method: 'post',
      url: '/class-session-topics',
      data,
    });
  }

  async editClassSessionTopic(id: number, data: ClassSessionTopicApi): Promise<ClassSessionTopicApi> {
    return await this._request<ClassSessionTopicApi>({
      method: 'put',
      url: `/class-session-topics/${id}`,
      data,
    });
  }

  async getClassSessionTopicsByCourse(courseId: string): Promise<Array<ClassSessionTopicApi>> {
    const { data } = await this._requestPaginated<ClassSessionTopicApi>({
      method: 'get',
      url: `/class-session-topics?courseId=${courseId}&$sort[class_session__id]=1&$sort[order]=1`,  //class_session_ids may not appear in the order of class session dates, but this grouping is still helpful
    });
    return data;
  }

  async getClassSessionTopicsForClassSessionIds(classSessionIds: Array<number>): Promise<Array<ClassSessionTopicApi>> {
    if (classSessionIds.length === 0) {
      return [];
    }
    const query = `class_session__id[$in][]=${classSessionIds.join('&class_session__id[$in][]=')}&$sort[class_session__id]=1&$sort[order]=1`;
    const { data } = await this._requestPaginated<ClassSessionTopicApi>({
      method: 'get',
      url: `/class-session-topics?${query}`,
    });
    return data;
  }

  async getClassSessionTopicsByClassSessionId(classSessionId: number): Promise<Array<ClassSessionTopicApi>> {
    const { data } = await this._requestPaginated<ClassSessionTopicApi>({
      method: 'get',
      url: `/class-session-topics?classSessionId=${classSessionId}&$sort[order]=1`,
    });
    return data;
  }

  async deleteClassSessionTopic(classSessionTopicId: number): Promise<ClassSessionTopicApi> {
    const result = await this._request<ClassSessionTopicApi>({
      method: 'delete',
      url: `/class-session-topics/${classSessionTopicId}`,
    });
    return result;
  }

  async createSignedLearnosityRequest(data: AssessmentInitApi): Promise<AssessmentInitApi> {
    return await this._request<AssessmentInitApi>({
      method: 'post',
      url: '/assessment-init',
      data,
    });
  }

  async createSignedLearnosityAuthorInit(data: AuthorInitApi): Promise<AuthorInitApi> {
    return await this._request<AuthorInitApi>({
      method: 'post',
      url: '/author-init',
      data,
    });
  }

  async createStudentAssessment(assessmentId: string, enrollmentId: number): Promise<StudentAssessmentApi> {
    return await this._request<StudentAssessmentApi>({
      method: 'post',
      url: '/student-assessments',
      data: {
        assessmentId,
        enrollmentId,
      },
    });
  }

  async getStudentAssessment(assessmentId: string, enrollmentId: number): Promise<Array<StudentAssessmentApi>> {
    const { data } = await this._requestPaginated<StudentAssessmentApi>({
      method: 'get',
      url: `/student-assessments?assessmentId=${assessmentId}&enrollmentId=${enrollmentId}`,
    });
    return data;
  }

  async getStudentAssessmentsByEnrollment(enrollmentId: number): Promise<Array<StudentAssessmentApi>> {
    const { data } = await this._requestPaginated<StudentAssessmentApi>({
      method: 'get',
      url: `/student-assessments?enrollmentId=${enrollmentId}`,
    });
    return data;
  }

  async getStudentAssessmentsByAssessments(assessmentIds: Array<string>, limit = 1000): Promise<Array<StudentAssessmentApi>> {
    if (!assessmentIds.length) {
      return [];
    }
    const query = `assessmentId[$in][]=${assessmentIds.join('&assessmentId[$in][]=')}&$sort[enrollment__id]=1&$limit=${limit}`;
    const { data } = await this._requestPaginated<StudentAssessmentApi>({
      method: 'get',
      url: `/student-assessments?${query}`,
    });
    return data;
  }

  // <Fetch SAQs>
  async getStudentAssessmentQuestions(studentAssessmentIds: Array<number>): Promise<Array<StudentAssessmentQuestionApiWithSaqas>> {
    if (!studentAssessmentIds.length) {
      return [];
    }
    const query = `studentAssessmentId[$in][]=${studentAssessmentIds.join('&studentAssessmentId[$in][]=')}&$sort[student_assessment__id]=1`;
    const { data } = await this._requestPaginated<StudentAssessmentQuestionApiWithSaqas>({
      method: 'get',
      url: `/student-assessment-questions?${query}`,
    });
    return data;
  }
  async getStudentAssessmentQuestionsByStudentAssessmentId(studentAssessmentId: number, skipSaqas = false): Promise<Array<StudentAssessmentQuestionApi | StudentAssessmentQuestionApiWithSaqas>> {
    // skipSaqas means don't include the extra *StudentAssessmentQuestionAttempt objects as part of the SAQ object in the response
    const saqSelect = '&$select[]=id&$select[]=points_available_to_recap';
    const query = `studentAssessmentId=${studentAssessmentId}&$sort[student_assessment__id]=1${skipSaqas ? saqSelect : ''}`;
    const { data } = await this._requestPaginated<StudentAssessmentQuestionApi | StudentAssessmentQuestionApiWithSaqas>({
      method: 'get',
      url: `/student-assessment-questions?${query}`,
    });
    return data;
  }
  async getStudentAssessmentQuestionsByAssessmentQuestions(assessmentQuestionIds: Array<number>): Promise<Array<Pick<StudentAssessmentQuestionApi, 'id' | 'assessmentQuestionId'>>> {
    if (!assessmentQuestionIds.length) {
      return [];
    }
    const query = `assessmentQuestionId[$in][]=${assessmentQuestionIds.join('&assessmentQuestionId[$in][]=')}&$sort[student_assessment__id]=1&$sort[assessment_question__id]=1&$select[]=id&$select[]=assessment_question__id&$limit=${limits.biggestPaginate.max}`;
    const { data } = await this._requestPaginated<StudentAssessmentQuestionApi>({
      method: 'get',
      url: `/student-assessment-questions?${query}`,
    });
    return data;
  }
  // </Fetch SAQs>

  async createStudentAttempt(studentAttemptData: Omit<StudentAssessmentQuestionAttemptApi, 'id' | 'attemptNum' | 'adjustedPointsEarned' | 'createdAt' | 'updatedAt'>): Promise<ErrorableResponse<StudentAssessmentQuestionAttemptApi>> {
    return await this._requestWithErrorHandling<StudentAssessmentQuestionAttemptApi>({
      method: 'post',
      url: '/student-assessment-question-attempts',
      data: studentAttemptData,
    });
  }

  async getSingleStudentAttemptsForAssessmentQuestions(assessmentQuestionIds: Array<number>, courseId: string): Promise<Array<StudentAssessmentQuestionAttemptApi>> {
    if (!assessmentQuestionIds.length) {
      return [];
    }
    const baseQuery = `assessmentQuestionId[$in][]=${assessmentQuestionIds.join('&assessmentQuestionId[$in][]=')}`;
    const { data } = await this._requestPaginated<StudentAssessmentQuestionAttemptApi>({
      method: 'get',
      url: `/student-assessment-question-attempts?${baseQuery}&courseId=${courseId}&oneAttemptPerQuestion=${YesNo.Yes}`,
    });
    return data;
  }

  async requestPasswordReset(email: string): Promise<unknown> {
    if (!email) {
      return;
    }

    const resetSendData = {
      value: {
        email,
      },
      action: 'sendResetPwd',
    };

    return await this._request({
      method: 'post',
      url: '/authmanagement',
      data: resetSendData,
    });
  }

  async changePassword(token: string, password?: string): Promise<unknown> {
    if (!token) {
      return;
    }

    const changePassData = {
      value: {
        token,
        password: password ?? token.split('__')[1],
      },
      action: 'resetPwdLong',
    };

    return await this._request({
      method: 'post',
      url: '/authmanagement',
      data: changePassData,
    });
  }

  async getL8yItemsByItemReferences(itemReferences: Array<string>): Promise<Array<L8yItem>> {
    if (!itemReferences.length) {
      console.warn('Attempting to getL8yItemsByItemReferences with empty array', itemReferences);
      return [];
    }
    const { data } = await this._request<{ data: Array<L8yItem> }>({
      method: 'get',
      url: `/l8y-items?itemReferences=${itemReferences.join(',')}&status=${Object.values(L8yItemStatus).join(',')}`,
    });
    return data;
  }

  async getStudentResponsesByAssessmentQuestion(assessmentQuestionId: number, resultsFormat: ResultsFormatEnum): Promise<StudentResponsesForAssessmentQuestionRes | string> {
    return await this._request<StudentResponsesForAssessmentQuestionRes | string>({
      method: 'get',
      url: `/student-responses?assessmentQuestionId=${assessmentQuestionId}&resultsFormat=${resultsFormat}`,
    });
  }

  async getStudentScoresData(courseId: string): Promise<ErrorableResponse<Array<StudentRow>>> {
    return await this._requestWithErrorHandling<Array<StudentRow>>({
      method: 'get',
      url: `/student-scores-data?courseId=${courseId}&resultsFormat=${ResultsFormatEnum.Json}`,
    });
  }

  async getStudentScoresDataCsv(courseId: string): Promise<ErrorableResponse<string>> {
    return await this._requestWithErrorHandling<string>({
      method: 'get',
      url: `/student-scores-data?courseId=${courseId}&resultsFormat=${ResultsFormatEnum.Csv}`,
    });
  }

  async getSystemReport(reportParams: SystemReportsRequest): Promise<Array<SaqasByTime> | Array<EnrollmentAndPayment> | Array<SaqaProjection>> {
    const paramString = Object.entries(reportParams).map(([key, value]) => `${key}=${value}`).join('&');
    return await this._request<Array<SaqasByTime> | Array<EnrollmentAndPayment> | Array<SaqaProjection>>({
      method: 'get',
      url: `/system-reports?${paramString}`,
    });
  }

  async initAssessmentScoreSync(assessmentId: string): Promise<ErrorableResponse<AssessmentScoreSyncApi>> {
    return await this._requestWithErrorHandling<AssessmentScoreSyncApi>({
      method: 'post',
      url: '/assessment-score-sync',
      data: {
        assessmentId,
      },
    });
  }

  async getAssessmentScoreSyncResultsByAssessmentId(assessmentId: string): Promise<Array<AssessmentScoreSyncApi>> {
    const { data } = await this._requestPaginated<AssessmentScoreSyncApi>({
      method: 'get',
      url: `/assessment-score-sync?assessmentId=${assessmentId}`,
    });
    return data;
  }

  async getAssessmentScoreSyncResultsById(jobId: string): Promise<AssessmentScoreSyncApi> {
    return await this._request<AssessmentScoreSyncApi>({
      method: 'get',
      url: `/assessment-score-sync/${jobId}`,
    });
  }

  async getAssessmentSummaryData(courseId: string, assessmentIds: Array<string>, view: AssessmentSummaryViewEnum): Promise<Array<AssessmentSummaryData | AssessmentQuestionSummaryDataLevel2Api>> {
    if (!assessmentIds.length) {
      return [];
    }
    const query = `courseId=${courseId}&assessmentId[$in][]=${assessmentIds.join('&assessmentId[$in][]=')}&view=${view}`;
    return await this._request<Array<AssessmentSummaryData | AssessmentQuestionSummaryDataLevel2Api>>({
      method: 'get',
      url: `/assessment-summary-data?${query}`,
    });
  }

  async getEnrollmentAssessmentsByEnrollment(enrollmentId: number): Promise<Array<EnrollmentAssessmentApi>> {
    const { data } = await this._requestPaginated<EnrollmentAssessmentApi>({
      method: 'get',
      url: `/enrollment-assessments?enrollmentId=${enrollmentId}`,
    });
    return data;
  }

  async getEnrollmentAssessmentsByAssessmentId(assessmentId: string): Promise<Array<EnrollmentAssessmentApi>> {
    const { data } = await this._requestPaginated<EnrollmentAssessmentApi>({
      method: 'get',
      url: `/enrollment-assessments?assessmentId=${assessmentId}`,
    });
    return data;
  }

  async getEnrollmentAssessmentsByAssessments(assessmentIds: Array<string>): Promise<Array<EnrollmentAssessmentApi>> {
    if (!assessmentIds.length) {
      return [];
    }
    const query = `assessmentId[$in][]=${assessmentIds.join('&assessmentId[$in][]=')}&$sort[assessment__id]=1&$sort[enrollment__id]=1`;
    const { data } = await this._requestPaginated<EnrollmentAssessmentApi>({
      method: 'get',
      url: `/enrollment-assessments?${query}`,
    });
    return data;
  }

  async createEnrollmentAssessment(enrollmentAssessmentData: EnrollmentAssessmentApi): Promise<EnrollmentAssessmentApi> {
    return await this._request<EnrollmentAssessmentApi>({
      method: 'post',
      url: '/enrollment-assessments',
      data: enrollmentAssessmentData,
    });
  }

  async editEnrollmentAssessment(enrollmentAssessmentData: EnrollmentAssessmentApi): Promise<EnrollmentAssessmentApi> {
    return await this._request<EnrollmentAssessmentApi>({
      method: 'put',
      url: `/enrollment-assessments/${enrollmentAssessmentData.id}`,
      data: enrollmentAssessmentData,
    });
  }

  async deleteEnrollmentAssessment(id: number): Promise<EnrollmentAssessmentApi> {
    return await this._request<EnrollmentAssessmentApi>({
      method: 'delete',
      url: `/enrollment-assessments/${id}`,
    });
  }

  /* STUDY PATH API CALLS */
  /* Student Study Path */
  async createStudentStudyPath(studyPathId: number, enrollmentId: number): Promise<StudentStudyPathApi> {
    return await this._request<StudentStudyPathApi>({
      method: 'post',
      url: '/student-study-paths',
      data: {
        studyPathId,
        enrollmentId,
      },
    });
  }
  async getStudentStudyPathByStudyPathIdAndEnrollment(studyPathId: number, enrollmentId: number): Promise<Array<StudentStudyPathApi>> {
    const startTime = performance.now();
    const { data } = await this._requestPaginated<StudentStudyPathApi>({
      method: 'get',
      url: `/student-study-paths?enrollmentId=${enrollmentId}&studyPathId=${studyPathId}`,
    });
    logExecutionTime(startTime, 'getStudentStudyPathByStudyPathIdAndEnrollment', 7000);
    return data;
  }
  /* Get single study path id from SP id */
  async getStudyPathById(studyPathId: number): Promise<Required<StudyPathApi>> {
    return await this._request<Required<StudyPathApi>>({
      method: 'get',
      url: `/study-paths/${studyPathId}`,
    });
  }
  /* Get single study path id from summative id */
  async getStudyPathBySummativeAssessmentId(summativeAssessmentId: string): Promise<Array<Required<StudyPathApi>>> {
    const { data } = await this._requestPaginated<Required<StudyPathApi>>({
      method: 'get',
      url: `/study-paths?summativeAssessmentId=${summativeAssessmentId}`,
    });
    return data;
  }
  /* Student Topic Cards */
  // unused but may come in handy for previewing SP?
  async getStudentTopicCardsByStudentStudyPathId(studentStudyPathId: number): Promise<Array<StudentTopicCardRes>> {
    const { data } = await this._requestPaginated<StudentTopicCardRes>({
      method: 'get',
      url: `/student-topic-cards?studentStudyPathId=${studentStudyPathId}`,
    });
    return data;
  }
  async editStudentTopicCard(studentTopicCard: Pick<StudentTopicCardReq, 'id' | 'source' | 'action'>): Promise<StudentTopicCardRes> {
    return await this._request<StudentTopicCardRes>({
      method: 'patch',
      url: `/student-topic-cards/${studentTopicCard.id}`,
      data: studentTopicCard,
    });
  }
  async editUserAgreement(): Promise<UserApi> {
    const userId = this.masqueradeUserId || this.userId;
    return await this._request<UserApi>({
      method: 'patch',
      url: `/users/${userId}`,
      data: {
        id: userId,
        agreementDate: new Date().toISOString(),
      },
    });
  }

  async postLogMessage(message: Array<string>): Promise<unknown> {
    return await this._request({
      method: 'post',
      url: '/log-shippers',
      data: {
        message,
      },
    });
  }

  async postDuplicateCourse(data: DuplicateCourseApi): Promise<ErrorableResponse<CourseApi>> {
    return await this._requestWithErrorHandling<CourseApi>({
      method: 'post',
      url: '/duplicate-courses',
      data,
    });
  }

  async postWorkerPipeline(service: string, method: ContextMethod, payload: GenericObject): Promise<WorkerPipelineApi> {
    return await this._request({
      method: 'post',
      url: '/worker-pipelines',
      data: {
        service,
        method,
        payload,
      },
    });
  }

  async getWorkerPipeline(id: string): Promise<WorkerPipelineApi> {
    return await this._request({
      method: 'get',
      url: `/worker-pipelines/${id}`,
    });
  }

  async createCopyQuestion(data: CopyQuestionApi): Promise<QuestionApiOut> {
    return await this._request<QuestionApiOut>({
      method: 'post',
      url: '/copy-questions',
      data,
    });
  }

  async getLatestCourseByParentCourseId(parentCourseId: string): Promise<CourseApi | undefined> {
    const { data } = await this._requestPaginated<CourseApi>({
      method: 'get',
      url: `/courses?parentCourseId=${parentCourseId}&$limit=1&$sort[created_at]=-1`,
    });
    const [latestCourse] = data;
    return latestCourse;
  }

  async getCourseHealthChecks(courseIds: Array<string>): Promise<Array<CourseHealthCheckResponseMulti>> {
    if (!courseIds.length) {
      return [];
    }
    const query = `courseId[$in][]=${courseIds.join('&courseId[$in][]=')}`;
    const response = await this._request<Array<CourseHealthCheckResponseMulti>>({
      method: 'get',
      url: `/course-health-check?${query}`,
    });
    return response;
  }

  async getCourseActivityChecks(courseIds: Array<string>): Promise<Array<CourseActivityCheckResponse>> {
    if (!courseIds.length) {
      return [];
    }
    const query = `courseId[$in][]=${courseIds.join('&courseId[$in][]=')}`;
    const response = await this._request<Array<CourseActivityCheckResponse>>({
      method: 'get',
      url: `/course-activity-check?${query}`,
    });
    return response;
  }

  async getInstitutions(): Promise<Array<InstitutionApi>> {
    const { data } = await this._requestPaginated<InstitutionApi>({
      method: 'get',
      url: '/institutions?$sort[name]=1',
    });
    return data;
  }

  async getInstitution(institutionId: number): Promise<InstitutionApi> {
    return await this._request<InstitutionApi>({
      method: 'get',
      url: `/institutions/${institutionId}`,
    });
  }

}

const apiNext = new ApiNext();
export default apiNext;
