import { createAsyncThunk } from '@reduxjs/toolkit';
import apiNext from 'api-next';
import { RootState } from 'store';
import { RoleEnum } from 'types/backend/roles.types';


export const loadCommonPassiveData = createAsyncThunk(
  'passive/loadCommonPassiveData',
  async (userId: string, thunkAPI) => {
    const { enrollments } = thunkAPI.getState() as RootState;
    const instructorCourseIds: Array<string> = [];
    const studentCourseIds: Array<string> = [];
    const allEnrolledCourseIds: Array<string> = [];
    for (const enrollment of enrollments) {
      if (enrollment.roleId === RoleEnum.Instructor) {
        instructorCourseIds.push(enrollment.courseId);
      } else if (enrollment.roleId === RoleEnum.Student) {
        studentCourseIds.push(enrollment.courseId);
      }
      if (!allEnrolledCourseIds.includes(enrollment.courseId)) {
        allEnrolledCourseIds.push(enrollment.courseId);
      }
    }

    // <fetch course instructors>
    const courseInstructors = await apiNext.getUsersByCoursesAndRole(allEnrolledCourseIds, RoleEnum.Instructor);
    const coinstructorsFromEnrolledCourses = courseInstructors.filter((ci) => ci.id !== userId);
    // </fetch course instructors>

    const [instructorCourses, studentCourses, products] = await Promise.all([
      apiNext.getCourses(instructorCourseIds),
      apiNext.getCourses(studentCourseIds),
      apiNext.getProducts(),
    ]);
    return {
      instructorCourses,
      studentCourses,
      products,
      coinstructorsFromEnrolledCourses,
      courseInstructors,
    };
  }
);

export const loadInstructorPassiveData = createAsyncThunk(
  'passive/loadInstructorPassiveData',
  async (userId: string, thunkAPI) => {
    console.debug(`Loading Instructor Passive Data for user: ${userId}`);
    const [subjects, units, topics] = await Promise.all([
      apiNext.getSubjects(),
      apiNext.getUnits(),
      apiNext.getTopics(),
    ]);

    return {
      subjects,
      units,
      topics,
    };
  }
);
